.container {
    position: relative;
}

.container * {
    font-size: initial;
    /*
        도대체 왜 style.module.css에 * 선택자에다가 font-size 62.5%를 적용한지 도무자 모르곘음 너무 어이가 없음...
        제거하려면 모든 페이지의 CSS를 다 수정해야 하는데 그럴 힘이 없어서 관두기로.
        나중에 제가 없을 때 수정하시게 되면 너무 죄송해요.
    */
}

.container img {
    filter: brightness(0%) invert(1);
    cursor: pointer;
    width: 36px;
    height: 36px;
}

.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 99999;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 300px;
}

.languageList {
    padding: 0;
    list-style: none;
}

.languageList li {
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
    color: white;
    cursor: pointer;
}

.languageList li:hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.menu {
    position: absolute;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    background: white;
    width: 150px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%) scaleY(0);
    transform-origin: 50% -10px;
    transition: transform 0.2s;
}

.menu::before {
    position: absolute;
    transform-origin: top;
    content: "";
    left: 50%;
    top: -20px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    border-bottom: 10px solid white;
    border-left: 10px solid rgba(0, 0, 0, 0);
}

.menu .item {
    font-size: 15px;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    text-align: center;
    transition: .5s;
}

.menu .item:hover {
    font-weight: 700;
    color: #F16722;
}

.menu .item:last-child {
    border-bottom: none;
}

@media (min-width: 1281px){

    .container img {
        pointer-events: none;
    }

    .container:hover .menu {
        transform: translateX(-50%) scaleY(1);
    }

    .modalContainer {
        display: none;
    }

}
