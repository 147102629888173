.section * {
    font-size: 16px;
    /*
        여기도 language.module.css 처럼 font-size initial...
    */
}

.sectionGray * {
    font-size: 16px;
    /*
        여기도 language.module.css 처럼 font-size initial...
    */
}

.mainTitle {
    margin-top: 200px;
    margin-bottom: 50px;
    font-size: 5rem;
    text-align: center;
}

.section {
    padding: 50px 16px;
    min-height: 100vh;
    box-sizing: border-box;
}

.sectionGray {
    padding: 50px 16px;
    background-color: #f5f5f6;
    min-height: 100vh;
    box-sizing: border-box;
}

.sectionTitle {
    margin-bottom: 25px;
    text-align: center;
    font-size: 3rem;
}

.sectionContent {

}

.sectionContent > * {
    margin-bottom: 40px;
}

.sectionContent > *:last-child {
    margin-bottom: 0;
}

.sectionDescription {
    text-align: center;
}

.sectionBody {
    text-align: center;
}

.sectionDescriptionTitle {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 10px;
}

.sectionDescriptionContent {
    margin-bottom: 20px;
}

.sectionDescriptionContent img {
    max-width: 100%;
}

/* 내용 스타일들 */

.section1Dot1ItemContainer {
    display: flex;
    max-width: 1500px;
    margin: 0 auto;
    gap: 20px;
}

.section1Dot1ItemContainer > .section1Dot1Item {
    width: 100%;
    margin: 0 auto;
    background-color: #f5f5f6;
    padding: 20px;
}

.section1Dot1ItemContainer > .section1Dot1Item > .section1Dot1ItemTitle {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.section1Dot1ItemContainer > .section1Dot1Item > .section1Dot1ItemDescription {
    text-align: center;
    margin-bottom: 20px;
}

.section1Dot1ItemContainer > .section1Dot1Item > .section1Dot1ItemDescription {
    text-align: center;
    margin-bottom: 20px;
}

.section1Dot1ItemContainer > .section1Dot1Item > .section1Dot1ImageContainer {
    display: flex;
    gap: 10px;
}

.section1Dot1ItemContainer > .section1Dot1Item > .section1Dot1ImageContainer img {
    width: 50%;
    max-width: 100%;
}

.section1Dot2ItemContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}

.section1Dot2Item > .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.section1Dot2Item > table td {
    border: 1px solid black;
    padding: 4px;
}

.section1Dot2Item .description {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
}

.section1Dot2Item .description:last-child {
    margin-bottom: 0;
}

.section1Dot2Item .description img {
    margin-right: 8px;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.section1Dot2Item .description .title {
    font-weight: bold;
    font-size: 1.2em;
}

.section1Dot3ItemContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}

.section1Dot3Item {
    margin-bottom: 20px;
    text-align: left;
}

.section1Dot3Item:nth-child(2) {
    margin-bottom: 20px;
}

.section1Dot3Item:last-child {
    margin-bottom: 0;
}

.section1Dot3Item {
    width: 100%;
    max-width: 400px;
}

.section1Dot3Item:first-child .imageContainer {
    position: relative;
    margin-bottom: 10px;
    background-color: #dddddd;
    border-radius: 5px;
    aspect-ratio: 1/1;
    max-width: 400px;
}

.section1Dot3Item:first-child .imageContainer img:nth-child(1) {
    position: absolute;
    top: 25%;
    right: 8%;
    transform: translateY(-50%);
    width: 150px;
    border-radius: 15px;
    z-index: 9;
}

.section1Dot3Item:first-child .imageContainer img:nth-child(2) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover;
    object-position: left center;
    aspect-ratio: 1 / 1.5;
    width: 200px;
}

.section1Dot3Item:first-child .imageContainer img:nth-child(3) {
    position: absolute;
    top: 75%;
    right: 8%;
    transform: translateY(-50%);
    width: 150px;
    border-radius: 15px;
}

.section1Dot3Item:first-child .description {
    text-align: center;
}

.section1Dot3Item:last-child .title {
    font-weight: bold;
    font-size: 1.2em;
}

.section1Dot3Item:last-child .description {
    margin-bottom: 10px;
}

.section1Dot4ItemContainer {
    display: flex;
    justify-content: center;
}

.section1Dot4Item .list {
    padding-left: 20px;
}

.section1Dot4Item .list li {
    text-align: left;
    list-style: square;
}

.section2Dot1ItemContainer {
    max-width: 800px;
    margin: 0 auto;
}

.section2Dot1Item img {
    width: 100%;
}

.section2Dot2ItemContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}

.section2Dot2Item {

}

.section2Dot2Item > .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.section2Dot2Item > table thead tr {
    background-color: #e0e0e0;
}

.section2Dot2Item > table :is(td, th) {
    border: 1px solid black;
    padding: 4px;
}

.section2Dot2Item .description {
    align-items: center;
    text-align: left;
    padding-left: 20px;
}

.section2Dot2Item .description li {
    text-align: left;
    list-style: square;
}

.section2Dot3ItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.section2Dot3Item .imageContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-left: 20px;
}

.section2Dot3Item .imageContainer img {
    width: 400px;
    max-width: 100%;
}

.section2Dot3Item .imageContainer img:nth-child(3) {
    object-position: right bottom;
    object-fit: cover;
    aspect-ratio: 15/9;
}

.section2Dot3Item .description {
    align-items: center;
    text-align: left;
    padding-left: 20px;
}

.section2Dot3Item .description li {
    text-align: left;
    list-style: square;
}

.section2Dot3Item > table {
    text-align: center;
}

.section2Dot3Item > table thead tr {
    background-color: #e0e0e0;
}

.section2Dot3Item > table :is(td, th) {
    border: 1px solid black;
    padding: 4px;
}

.section2Dot3Item > table col {
    width: 20%;
}

.section3Dot1ItemContainer {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.section3Dot1Item {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section3Dot1Item > .title {
    font-weight: bold;
    font-size: 1.2em;
}

.section3Dot1Item img {
    max-width: 100%;
    margin-bottom: 10px;
}

.section3Dot1Item > .description > .title {
    font-weight: bold;
    margin-bottom: 10px;
}

.section3Dot1Item > .description > ul {
    padding-left: 20px;
    text-align: left;
}

.section3Dot1Item > .description > ul li {
    list-style: square;
}

.section3Dot2Item {
    display: flex;

}

.section3Dot2Item .imageContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 100%;
}

.section3Dot2Item .imageContainer > div {
    display: flex;
    margin: 0 auto;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 100%;
}

.section3Dot2Item .imageContainer > div > div {
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
}

.section3Dot2Item .imageContainer > div > div img {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    max-width: 100%;
}

@media (max-width: 1280px) {

    .section1Dot1ItemContainer {
        flex-wrap: wrap;
    }

    .section1Dot1ItemContainer > .section1Dot1Item {
        max-width: 600px;
    }

}

@media (max-width: 768px) {

    .container * {
        font-size: 13px;
    }

    .mainTitle {
        font-size: 3.6rem;
        margin-top: 100px;
    }

    .section1Dot3Item:first-child .imageContainer img:nth-child(1) {
        width: 125px;
    }

    .section1Dot3Item:first-child .imageContainer img:nth-child(2) {
        width: 175px;
    }

    .section1Dot3Item:first-child .imageContainer img:nth-child(3) {
        width: 125px;
    }

}

@media (max-width: 360px) {

    .section1Dot3Item:first-child .imageContainer img:nth-child(1) {
        width: 100px;
    }

    .section1Dot3Item:first-child .imageContainer img:nth-child(2) {
        width: 150px;
    }

    .section1Dot3Item:first-child .imageContainer img:nth-child(3) {
        width: 100px;
    }

}
