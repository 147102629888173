.dealership .bg {
	background: url('/public/images/dealership-bg.jpg') no-repeat center/cover;
	filter: brightness(0.60);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dealership .mainText {
	width: 100%;
}

.dealership {
	color: white;
	font-size: 16px;
}

.dealership {
	color: inherit;
	font-size: inherit;
}

.content {
	margin: 100px 0;
	font-size: 16px;
}

.content * {
	font-size: inherit;
}

.content .list {
	margin: 0 16px;
	padding: 16px;
	text-align: center;
}

.content .list li {
	font-size: 30px;
}

.content .finding {
	margin-top: 32px;
	padding: 16px;
	font-size: 32px;
	text-align: center;
}

h2 .description:nth-child(2) {
	margin: 16px;
	font-size: 32px;
	text-align: center;
	word-break: break-word;
	white-space: wrap;
}

.description .contact {
	font-size: 24px;
}

.dealership * {
	font-size: inherit;
	color: inherit;
}

.reference {
	position: absolute;
	font-size: 12px;
	color: white;
	bottom: 16px;
	right: 5%;
}
.reference a {
	text-decoration: underline;
}

@media (max-width: 1000px) {

	h2 .description:nth-child(2) {
		font-size: 24px;
	}

	.description .contact {
		font-size: 20px;
	}

}

@media (max-width: 900px) {

	/*.content .list, .content .finding, .content .description {*/
	/*	max-width: 650px;*/
	/*}*/

	h2 .description:nth-child(2) {
		word-break: break-word;
	}

	.content .list li {
		font-size: 22px;
	}

	.content .finding {
		font-size: 25px;
	}

}

@media (max-width: 750px) {

	h2 .description:nth-child(2) {
		word-break: break-word;
		font-size: 20px;
	}

	.description .contact {
		font-size: 16px;
	}

}

@media (max-width: 700px) {

	/*.content .list, .content .finding, .content .description {*/
	/*	max-width: 360px;*/
	/*}*/

	.content .list li {
		font-size: 18px;
	}

	.content .finding {
		font-size: 22px;
	}

}
